.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.phone-field{
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;
}
.phone-field label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  padding-left: 5px;
}

.phone-field label,.phone-field input {
  transition: all 0.2s;
  touch-action: manipulation;
}

.phone-field input:placeholder-shown + label {
cursor: text;
max-width: 66.66%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
transform-origin: left bottom;
transform: translate(0, 2.125rem) scale(1.5);
}

.phone-field input:not(:placeholder-shown) + label,
.phone-field input:focus + label {
transform: translate(0, 0) scale(1);
cursor: pointer;
}
.react-tel-input .selected-flag:hover{background-color: 'transparent !important';}

svg.breadCrum {
  fill: white;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}