html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: #F1F4F7 !important;
  padding: 0 !important;
  box-sizing: border-box;
  /* overflow: hidden; */
}

.chart-container {
  width: 100vw;
  height: 100vh;
  padding: 16px;
}